import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import { HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <span>ViaMedia</span>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {/* <li className="nav-item" key="adventTableDecorationProductMenuItem">
              <NavLink
                exact
                to="/adventTableDecorationProduct"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Adventi asztaldísz
              </NavLink>
            </li> */}

            <li className="nav-item" key="chakraCrossMenuItem">
              <NavLink
                exact
                to="/chakraCross"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Karkötő kereszt medállal
              </NavLink>
            </li>
            
            <li className="nav-item" key="crossfishNecklaceMenuItem">
              <NavLink
                exact
                to="/crossfishNecklace"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Hal+Kereszt nyaklánc
              </NavLink>
            </li>
            
            <li className="nav-item" key="crossKeychainMenuItem">
              <NavLink
                exact
                to="/crossKeychain"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Jézus kereszt medál kulcstartó
              </NavLink>
            </li>
            <li className="nav-item" key="silverCrossBracletMenuItem">
              <NavLink
                exact
                to="/silverCrossBraclet"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Ezüst kereszt karkötő
              </NavLink>
            </li>
            <li className="nav-item" key="jesusSuncatcherMenuItem">
              <NavLink
                exact
                to="/jesusSuncatcher"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Jézus Krisztus ablakdísz
              </NavLink>
            </li>
            <li className="nav-item" key="necklaceWithEyesMenuItem">
              <NavLink
                exact
                to="/necklaceWithEyes"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Nyaklánc szem medállal
              </NavLink>
            </li>
            <li className="nav-item" key="rosaryMenuItem">
              <NavLink
                exact
                to="/rosary"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Rózsafűzér
              </NavLink>
            </li>
            <li className="nav-item" key="necklaceWithCrossMenuItem">
              <NavLink
                exact
                to="/necklaceWithCross"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Nyaklánc kereszt medállal
              </NavLink>
            </li>
            <li className="nav-item" key="chakraBraceletMenuItem">
              <NavLink
                exact
                to="/chakraBracelet"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Csakra karkötő
              </NavLink>
            </li>
            <li className="nav-item" key="aventurineBraceletMenuItem">
              <NavLink
                exact
                to="/aventurineBracelet"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Aventurin karkötő
              </NavLink>
            </li>
            <li className="nav-item" key="bookmarkMenuItem">
              <NavLink
                exact
                to="/bookmark"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Bibliai könyvjelző
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
                <HamburgetMenuOpen />{" "}
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuClose />
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
